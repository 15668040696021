<template>
	<div>
		<div class="special" v-if="searchObj.selected.data.length > 0">
			<div
				class="specialItem"
				v-for="(item, index) in searchObj.selected.data"
				:key="index"
			>
				<div class="flex align_items_c specialItem_1">
					<div class="specialItem_1_bs flex align_items_c justify_content_c"
						>专题</div
					>
					<div
						class="pointer hovercolor"
						@click="
							$router.push({ name: '/home_jszk', params: { id: item.id } })
						"
						>{{ item.title }}</div
					>
				</div>
				<div class="specialItem_2">
					{{ item.title }}
				</div>
			</div>
		</div>
		<!-- <noData v-else></noData> -->
	</div>
</template>

<script>
export default {
	name: "",
	props: ["searchObj"],
};
</script>

<style scoped>
.special {
	margin-top: 16px;
}
.specialItem {
	margin-top: 18px;
	padding-bottom: 18px;
	border-bottom: 1px solid #f5f5f5;
}
.specialItem_1_bs {
	width: 34px;
	height: 20px;
	background-image: url("https://res.metaera.hk/resources/assets/images/search/246.png");
	background-size: 100% 100%;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}
.specialItem_1 div:nth-child(2) {
	margin-left: 8px;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}
.specialItem_2 {
	margin-top: 10px;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #74747e;
}
</style>
