<template>
	<div class="zh_searchkeyword">
		<div
			v-if="
				searchObj != null &&
				searchObj.column_list != null &&
				searchObj.article_list.length > 0
			"
			class="zh_searchkeyword_main"
		>
			<div class="flex justify_content_sb align_items_c jxBox wzmore">
				<div class="jxT"> 文章 </div>
				<div class="more pointer" @click="$emit('changeActiveName', '3')">
					更多
				</div>
			</div>
			<div class="wzList2">
				<div
					class="wzItem2 pointer"
					v-for="(item, index) in searchObj.article_list"
					:key="index"
				>
					<router-link
						target="_blank"
						:to="{ name: '/gw_detail', params: { id: item.id } }"
					>
						<wzitem :item="item"></wzitem>
					</router-link>
				</div>
			</div>
		</div>
		<div
			v-if="
				searchObj != null &&
				searchObj.column_list != null &&
				searchObj.flash_list.length > 0
			"
			class="steps kxstepssou zh_searchkeyword_main"
		>
			<div class="flex justify_content_sb align_items_c jxBox wzmore">
				<div class="jxT"> 快讯 </div>
				<div class="more pointer" @click="$emit('changeActiveName', '4')">
					更多
				</div>
			</div>
			<el-steps class="wzitem_main" direction="vertical" :active="10">
				<div
					class="wzItemS pointer"
					v-for="(item, index) in searchObj.flash_list"
					:key="index"
				>
					<router-link
						target="_blank"
						:to="{ name: '/gw_detail', params: { id: item.id } }"
					>
						<wzitem :item="item" :contentflag="true"></wzitem>
					</router-link>
				</div>
			</el-steps>
		</div>
		<div
			class="author_main zh_searchkeyword_main"
			v-if="
				searchObj != null &&
				searchObj.column_list != null &&
				searchObj.column_list.length > 0
			"
		>
			<div class="flex justify_content_sb align_items_c jxBox wzmore">
				<div class="jxT"> 作者 </div>
				<div class="more pointer" @click="$emit('changeActiveName', '6')">
					更多
				</div>
			</div>
			<div class="wzitem_main author">
				<div
					class="authorItem flex align_items_c pointer"
					v-for="(item, index) in searchObj.column_list"
					:key="index"
					@click="$router.push({ name: '/home_wz', params: { id: item.id } })"
				>
					<img
						v-if="item.avatar_img != null && item.avatar_img.length > 0"
						v-lazy="item.avatar_img"
						:key="item.avatar_img"
						alt=""
						class="authorItemHead"
					/>

					<img
						src="https://res.metaera.hk/resources/user_header.png"
						alt=""
						class="authorItemHead"
						v-else
					/>
					<div class="authorItem_r flex direction_column justify_content_sb">
						<div class="flex align_items_c">
							<div>
								<span>{{ item.nickname }}</span>
								<img
									src="https://res.metaera.hk/resources/assets/images/detail/94.png"
									alt=""
								/>
							</div>
							<p class="focusbtn" @click.stop="focusAuthor(item)">+ Focus</p>
						</div>

						<div
							v-if="item.desc != null && item.desc.length > 0"
							class="authorItem_desc"
							>{{ item.desc }}</div
						>
						<div v-else class="authorItem_desc"
							>全球领先的中文区块链媒体及服务平台</div
						>

						<div>共{{ item.num }}篇文章</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { myMixin } from "@/utils/mixin.js";
import wzitem from "./../../components/pc/wzitem.vue";
export default {
	mixins: [myMixin],
	components: {
		wzitem,
	},
	name: "",
	props: ["searchObj"],
	methods: {
		focusAuthor(item) {
			alert("关注作者" + item);
		},
	},
};
</script>

<style scoped>
.zh_searchkeyword_main {
	margin-bottom: 40px;
}
.zh_searchkeyword {
	margin-top: 0px;
}

.zh_searchkeyword_1 img {
	width: 68px;
	height: 68px;
}

.zh_searchkeyword_2 {
	margin-left: 30px;
}

.keywords {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.wordsDea {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #767680;
	line-height: 22px;
	margin-top: 6px;
}

.jxT {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
}

.more {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #767680;
}

.jxBox {
	margin-top: 10px;
}

.jxItem {
	margin-top: 30px;
}

.jxItem:nth-child(1) {
	margin-top: 10px;
}

.jxItem div:nth-child(1) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #1a1b1c;
}

.jxItem div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a0a0a9;
	margin-top: 10px;
}

.wzmore {
	color: #000;
	margin-top: 0px;
}

.wzmore .jxT {
	color: #fff;
}

.wzItem2 div:nth-child(1) {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.wzItem2 div:nth-child(2) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #74747e;
	margin-top: 10px;
}

.wzItem2 div:nth-child(3) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #74747e;
	margin-top: 7px;
}
.author {
	margin-top: 10px;
	padding-top: 20px;
	padding-bottom: 10px;
}
.authorItem {
	margin-bottom: 20px;
}
.authorItemHead {
	width: calc(100vw * 80 / 1200);
	height: calc(100vw * 80 / 1200);
	border-radius: 50%;
}
.authorItem_r {
	margin-left: 10px;
}
.authorItem_r span {
	font-size: calc(100vw * 14 / 1200);
	line-height: 1;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #fff;
}
.authorItem_r img {
	margin-left: 6px;
	width: 14px;
	height: 14px;
}
.focusbtn {
	margin-left: 12px;
	padding: 7px 11px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	color: #fff;
	font-size: 12px;
	line-height: 1;
	font-weight: 600;
}
.authorItem_r div:nth-child(2),
.authorItem_r div:nth-child(3) {
	margin-top: 10px;
	font-size: 12px;
	line-height: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #aaa;
}

.authorItem_desc {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.btnitem {
	width: 80px;
}

.wzList2 {
	border-radius: 20px;
	opacity: 1;
	/* background: #ffffff; */
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}

@media screen and (max-width: 900px) {
	.zh_searchkeyword {
		margin-top: 10px;
	}
	.wzmore {
		color: #000;
		margin-top: 8px;
		padding-bottom: 19px;
		border-bottom: 1px solid #f5f5f5;
	}
	.jxT {
		font-size: calc(100vw * 16 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #0056ff;
	}

	.more {
		font-size: calc(100vw * 12 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #767680;
	}
	.wzItem2 div:nth-child(1) {
		font-size: calc(100vw * 14 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 400;
		color: #000000;
	}

	.wzItem2 div:nth-child(2) {
		font-size: calc(100vw * 12 / 375);

		font-weight: 400;
		color: #74747e;
		margin-top: 10px;
	}

	.wzItem2 div:nth-child(3) {
		font-size: calc(100vw * 12 / 375);

		font-weight: 400;
		color: #74747e;
		margin-top: 7px;
	}
	.el-step .is-vertical,
	.el-step__main,
	.is-vertical {
		height: calc(100vw * 90 / 375) !important;
		flex-basis: auto !important;
	}
	.el-step__description {
		line-height: calc(100vw * 30 / 375);
	}
	.stepsTime {
		font-size: calc(100vw * 14 / 375);
		height: calc(100vw * 20 / 375);
		margin-top: 0;
	}
	.stepsVal {
		font-size: calc(100vw * 16 / 375);
		height: calc(100vw * 24 / 375);
		margin-top: 0;
		padding-top: calc(100vw * 10 / 375);
	}
	.kxstepsBox {
		height: calc(100vw * 15 / 375);
	}
	.kxstepsBox a {
		font-size: calc(100vw * 12 / 375);
	}
	.kxstepsBoxLeft {
		width: auto;
	}
	.btnitem {
		width: calc(100vw * 60 / 375);
	}
	.btnitem div,
	.btnitem a {
		font-size: calc(100vw * 12 / 375);
	}
	.btnitem img {
		width: calc(100vw * 12 / 375);
		height: calc(100vw * 12 / 375);
	}
	.authorItem {
		margin-top: calc(100vw * 12 / 375);
	}
	.authorItemHead {
		width: calc(100vw * 40 / 375);
		height: calc(100vw * 40 / 375);
		border-radius: calc(100vw * 20 / 375);
	}
	.authorItem_r {
		margin-left: calc(100vw * 10 / 375);
		height: calc(100vw * 60 / 375);
	}
	.authorItem_desc {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.authorItem_r span {
		font-size: calc(100vw * 14 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 400;
		color: #000000;
	}
	.authorItem_r img {
		margin-left: 6px;
		width: calc(100vw * 14 / 375);
		height: calc(100vw * 14 / 375);
	}
	.authorItem_r div:nth-child(2),
	.authorItem_r div:nth-child(3) {
		margin-top: 10px;
		font-size: 12px;
		line-height: 16px;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		color: #aaa;
	}
}
</style>

<style>
@media screen and (max-width: 900px) {
	.steps .el-step__icon {
		width: calc(100vw * 16 / 375) !important;
		height: calc(100vw * 16 / 375) !important;
	}
	.steps .el-step.is-vertical .el-step__line {
		left: calc(100vw * 7 / 375) !important;
	}
	.kxstepssou .el_steps .el-step__main {
		margin-left: calc(100vw * 16 / 375) !important;
		margin-top: calc(100vw * 3 / 375) !important;
	}
	.stepsVal .ellipsis_one .pointer .hovercolor {
		color: #000000;
		border-color: #dedee1;
	}
	.kxstepssou .el_steps .el-step__head .el-step__icon .el-step__icon-inner {
		font-size: calc(100vw * 10 / 375);
	}
}
</style>
