<template>
	<div
		class="author wzitem_main"
		v-if="
			searchObj != null &&
			searchObj.column_list != null &&
			searchObj.column_list.length > 0
		"
	>
		<div
			class="authorItem flex align_items_c pointer"
			v-for="(item, index) in searchObj.column_list"
			:key="index"
			@click="$router.push({ name: '/home_wz', params: { id: item.id } })"
		>
			<img
				v-lazy="item.avatar_img"
				:key="item.avatar_img"
				alt=""
				class="authorItemHead"
			/>
			<div class="authorItem_r flex direction_column justify_content_sb">
				<div class="flex align_items_c">
					<div>
						<span>{{ item.nickname }}</span>
						<img
							src="https://res.metaera.hk/resources/assets/images/detail/94.png"
							alt=""
						/>
					</div>
					<p class="focusbtn" @click.stop="focusAuthor(item)">+ Focus</p>
				</div>
				<div class="authorItem_desc">{{ item.desc }}</div>
				<div>共{{ item.num }}篇文章</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: ["searchObj"],
	methods: {
		focusAuthor(item) {
			alert("关注作者" + item);
		},
	},
};
</script>

<style scoped>
.author {
	margin-top: 10px;
	padding-top: 20px;
	padding-right: 20px;
	padding-bottom: 10px;
}
.authorItem {
	margin-bottom: 30px;
}
.authorItemHead {
	width: calc(100vw * 80 / 1200);
	height: calc(100vw * 80 / 1200);
	border-radius: 50%;
}
.authorItem_r {
	margin-left: 10px;
}
.authorItem_r span {
	font-size: calc(100vw * 14 / 1200);
	line-height: 1;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #fff;
}
.authorItem_r img {
	margin-left: 6px;
	width: 14px;
	height: 14px;
}
.focusbtn {
	margin-left: 12px;
	padding: 7px 11px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	color: #fff;
	font-size: 12px;
	line-height: 1;
	font-weight: 600;
}
.authorItem_r div:nth-child(2),
.authorItem_r div:nth-child(3) {
	margin-top: 10px;
	font-size: 12px;
	line-height: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #aaa;
}

.authorItem_desc {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
@media screen and (max-width: 900px) {
	.authorItem {
		margin-top: calc(100vw * 12 / 375);
	}
	.authorItemHead {
		width: calc(100vw * 40 / 375);
		height: calc(100vw * 40 / 375);
		border-radius: calc(100vw * 20 / 375);
	}
	.authorItem_r {
		margin-left: calc(100vw * 10 / 375);
		height: calc(100vw * 60 / 375);
	}
	.authorItem_desc {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.authorItem_r span {
		font-size: calc(100vw * 14 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 400;
		color: #000000;
	}
	.authorItem_r img {
		margin-left: 6px;
		width: calc(100vw * 14 / 375);
		height: calc(100vw * 14 / 375);
	}
	.authorItem_r div:nth-child(2),
	.authorItem_r div:nth-child(3) {
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 400;
		color: #a0a0a8;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}
</style>
