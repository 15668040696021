<template>
	<div class="result-pc padding_120">
		<div class="flex align_items_c justify_content_sb">
			<div class="searchBox flex align_items_c">
				<img
					src="https://res.metaera.hk/resources/assets/images/tab/h_search.png"
					alt=""
					@click="searchFun"
					class="pointer"
				/>
				<input type="text" v-model="keywords" @keydown.enter="searchFun" />
			</div>
			<img
				@click="
					$router.push({
						path: '/search',
					})
				"
				class="closeBtn"
				src="https://res.metaera.hk/resources/assets/images/search/close_btn.png"
			/>
		</div>
		<div class="flex justify_content_sb">
			<div class="MainLeft MainLeft_760">
				<div class="tagBoxCon">
					<div class="tageBox">
						<el-tabs v-model="activeName">
							<el-tab-pane label="综合" name="1">
								<zh_searchkeyword
									:searchObj="searchObj"
									@changeActiveName="changeActiveName"
								>
								</zh_searchkeyword>
							</el-tab-pane>
							<el-tab-pane label="文章" name="3">
								<wz :searchObj="searchObj"></wz>
							</el-tab-pane>

							<el-tab-pane label="快讯" name="4">
								<div class="steps kxsteps" v-if="searchObj.flash_list">
									<el-steps
										direction="vertical"
										:active="1"
										class="wzitem_main"
										style="margin-top: 10px"
									>
										<div
											class="wzItemS pointer"
											v-for="(item, index) in searchObj.flash_list"
											:key="index"
										>
											<router-link
												target="_blank"
												:to="{ name: '/gw_detail', params: { id: item.id } }"
											>
												<wzitem :item="item" :contentflag="true"></wzitem>
											</router-link>
										</div>
									</el-steps>
								</div>
							</el-tab-pane>
							<el-tab-pane label="专栏作者" name="6">
								<author :searchObj="searchObj"></author>
							</el-tab-pane>
							<el-tab-pane label="活动" name="7">
								<div
									v-for="(item, index) in searchObj.activity_list"
									:key="index"
								>
									<acItem :item="item" :type="1" @gotoDetail="gotoDetail">
									</acItem>
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
			<div class="MainRight MainRight_400">
				<module-part type="1"> </module-part>
				<module-part type="4"> </module-part>
				<module-part type="5"> </module-part>
				<!-- <resou></resou> -->
				<!-- <zixun
					:selarticleList="searchObj.flash_list"
					:partName="'24H 重要资讯'"
				></zixun> -->
			</div>
		</div>
	</div>
</template>

<script>
import AraleQRCode from "arale-qrcode";
import zhuanti from "./zhuanti.vue";
import wz from "./wz.vue";
import zh_searchkeyword from "./zh_searchkeyword.vue";
import author from "./author.vue";
import message from "./message.vue";
import { search } from "@/api/home";
import { myMixin } from "@/utils/mixin.js";
import wzitem from "./../../components/pc/wzitem.vue";
// import zixun from "./../../components/zixun.vue";
import modulePart from "@/components/modulePart.vue";
import acItem from "./acItem.vue";
export default {
	mixins: [myMixin],
	name: "",
	components: {
		zhuanti,
		wz,
		zh_searchkeyword,
		author,
		wzitem,
		message,
		acItem,
		modulePart,
		// zixun,
	},
	data() {
		return {
			tupian: "",
			activeName: "1",
			showsx: false,
			type: "",
			time_type: "5",
			order_type: "3",
			searchObj: {},
			keywords: "",
			category: [
				{
					name: "顺序",
					key: "order_type",
					order_type: "3",
					list: [
						{
							name: "互动最多",
							val: "1",
						},
						{
							name: "阅读最多",
							val: "2",
						},
						{
							name: "最新发布",
							val: "3",
						},
					],
				},
				{
					name: "时间",
					key: "time_type",
					time_type: "5",
					list: [
						{
							name: "一天内",
							val: "1",
						},
						{
							name: "一周内",
							val: "2",
						},
						{
							name: "一个月内",
							val: "3",
						},
						{
							name: "三个月内",
							val: "4",
						},
						{
							name: "一年内",
							val: "5",
						},
					],
				},
			],
			config: {},
		};
	},
	created() {
		this.keywords = this.$route.params.key;
		this.search(this.$route.params.key);
	},
	mounted() {},
	methods: {
		gotoDetail(item) {
			this.$router.push({
				name: "/ac_detail",
				params: { id: item.id },
			});
		},
		handleEnterKey() {
			this.searchFun();
		},
		changeActiveName(val) {
			this.activeName = val;
			console.log(this.activeName, "这里怎么回到顶部啊");
		},
		search(key) {
			if (key.length > 0) {
				let _obj = JSON.parse(sessionStorage.getItem("searchRecords"));
				if (_obj == null) {
					_obj = [];
				}
				var tmpData = { name: key };
				_obj.push(tmpData);
				sessionStorage.setItem("searchRecords", JSON.stringify(_obj));
			}
			search({
				key: key,
				type: this.type,
				time_type: this.time_type,
				order_type: this.order_type,
			})
				.then((res) => {
					if (res.code == 200) {
						this.searchObj = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		searchFun() {
			this.search(this.keywords);
		},
		changeCate(item, items, indexs) {
			item[item.key] = items.val;
			item.key == "order_type"
				? (this.order_type = item.order_type)
				: (this.time_type = item.time_type);
			this.searchFun();
			this.showsx = false;
		},
	},
};
</script>

<style scoped>
.result-pc {
	min-height: calc(100vh - 100px);
	padding-top: 30px;
}
.MainRight {
	padding-top: 25px;
}
.closeBtn {
	width: 24px;
	height: 24px;
	margin-left: 40px;
}
.searchBox {
	width: 100%;
	height: 50px;
	border-radius: 17px;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 4.68px 15.3px 68px 0px rgba(54, 62, 147, 0.0902);
}

.searchBox img {
	width: 22px;
	height: 22px;
	margin-left: 20px;
	margin-right: 10px;
}
.searchBox input {
	width: 80%;
	height: 90%;
	border: none;
	outline: none;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #fff !important;
	background: transparent;
}

.tagBoxCon {
	margin-top: 29px;
	position: relative;
}

.tagBox {
	width: 100%;
}

.sxBtn {
	position: absolute;
	right: 0;
	top: 10px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #767680;
}

.MainRight_1 {
	width: 100%;
	height: 104px;
	background: #f9f9f9;
	border-radius: 8px 8px 8px 8px;
	opacity: 0.6;
}

.MainRight_1_left {
	margin-left: 24px;
}

.MainRight_1_left img {
	width: 55px;
	height: 55px;
}

.MainRight_1_Right {
	margin-left: 18px;
}

.MainRight_1_Right div:nth-child(1) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.MainRight_1_Right div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a1a1ab;
	line-height: 17px;
	margin-top: 3px;
}

.newsImgE {
	width: 130px;
	margin-left: 16px;
}

.MainRight_2 {
	margin-top: 76px;
	padding-left: 0;
	border: 0;
}

.MainRight_3 {
	margin-top: 36px;
}
.MainRight_3_main {
	margin-top: 10px;
	padding-top: 10px;
	padding-left: 20px;
	padding-right: 20px;
}
.MainRight_3_title {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	padding-bottom: 18px;
	border-bottom: 1px solid #f5f5f5;
}

.zlzzItem {
	margin-top: 20px;
}

.zlzzItem img {
	border-radius: 50px;
	width: 45px;
	height: 45px;
}

.zlzzItem_r {
	padding-bottom: 20px;
	border-bottom: 1px solid #f5f5f5;
	margin-left: 13px;
	height: 60px;
}
.zlzzItem_desc {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.zlzzItem_r div:nth-child(1) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.zlzzItem_r div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a0a0a9;
	line-height: 19px;
}

.MainRight_3_title {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.sxBox {
	width: 1200px;
	position: absolute;
	top: 0;
	right: 0;
}

.sxItem {
	margin-top: 20px;
}

.sxItem:nth-child(1) {
	margin-top: 0;
}

.sxItem_title {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.sxItem_btn_list {
	margin-top: 18px;
}

.sxItem_btn_item {
	margin-left: 12px;
	padding: 0 22px;
	height: 30px;
	background: #ffffff;
	border-radius: 16px 16px 16px 16px;
	opacity: 1;
	border: 1px solid #f5f5f5;
}

.sxItem_btn_item:nth-child(1) {
	margin-left: 0;
}

.sxCon {
	padding: 20px 0 30px 35px;
	position: absolute;
	top: 60px;
	background: #fff;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	width: calc(100% - 35px);
}
.activeSx {
	border: 1px solid #0056ff;
	color: #0056ff;
}
.btnitem {
	width: 80px;
}
.tagBoxCon .el-tabs__nav-wrap::after {
	background-color: #f5f5f5;
}

.tagBoxCon .el-tabs__item {
	height: 46px;
}
</style>
<style>
.result-pc .el-tabs__header {
	margin-bottom: calc(100vw * 30 / 1440);
}
.btnitem img {
	height: 16px;
}

.btnitem div {
	margin-left: 4px;
	height: 18px;
	font-size: 14px;
}
.el-popover {
	height: 284px;
	top: 256px !important;
	left: 0 !important;
	width: 750px !important;
}
</style>

<style>
.el-step__main {
	margin-left: 16px !important;
	margin-top: 0 !important;
	padding-bottom: 10px;
}
.stepsTime {
	margin-bottom: 10 !important;
}
</style>
